import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "./App.css";

function App() {
  const [showHelp, toggleShowHelp] = useState(false);
  const [triangleResult, setTriangleResult] = useState("");
  const { register, handleSubmit, reset } = useForm();

  function evaluateResult(data: any) {
    const { one, two, three } = data;

    if (one === two && one === three) {
      return setTriangleResult("Equilateral");
    } else if (one === two || one === three || two === three) {
      return setTriangleResult("Isosceles");
    } else {
      return setTriangleResult("Scalene");
    }
  }

  function resetForm() {
    reset();
    setTriangleResult("");
  }

  return (
    <div className="App">
      <div>
        <h1>Please enter triangle sides</h1>
        <button onClick={() => toggleShowHelp(!showHelp)}>?</button>
      </div>
      {showHelp && <div>Help</div>}
      <form onSubmit={handleSubmit(evaluateResult)}>
        <label>
          Side One:
          <input name="one" type="text" ref={register} />
        </label>

        <label>
          Side Two:
          <input name="two" type="text" ref={register} />
        </label>

        <label>
          Side Three:
          <input name="three" type="text" ref={register} />
        </label>

        <input type="submit" value="Evaluate" />
      </form>
      {triangleResult && (
        <div>
          <p>Your triangle is</p>
          <p>{triangleResult}</p>
          <button onClick={resetForm}>clear</button>
        </div>
      )}
    </div>
  );
}

export default App;
